'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, t } from '@lingui/macro';
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import {
  CircularProgress,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { useApi } from '@prismo-io/core';
import {
  Button,
  PhoneNumberInput,
  TextFieldInput,
} from '@prismo-io/design-system';
import { type FC, type ReactNode, useEffect, useMemo } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { InformationsSchemaRefine, type InformationsSchemaT } from './schema';

export type InformationsFormProps = {
  onSubmit: SubmitHandler<InformationsSchemaT>;
  defaultValues?: Partial<InformationsSchemaT>;
  disabled?: Partial<Record<keyof InformationsSchemaT, boolean>> & {
    withEmailVerification?: boolean;
    withOrganizationNameVerification?: boolean;
  };
  hidden?: Partial<Record<keyof InformationsSchemaT, boolean>>;
  submitBtns?: ReactNode;
};

export const InformationsForm: FC<InformationsFormProps> = (props) => {
  const {
    onSubmit: onNext,
    defaultValues = {},
    submitBtns = undefined,
    disabled = {},
    hidden = {},
  } = props;

  const { client } = useApi();

  const formMethods = useForm<InformationsSchemaT>({
    mode: 'all',
    resolver: zodResolver(
      InformationsSchemaRefine(
        client,
        disabled?.withOrganizationNameVerification ?? true
      )
    ),
    defaultValues: {
      ...defaultValues,
    },
  });

  const {
    control,
    formState: {
      isValid,
      isSubmitting,
      isSubmitSuccessful,
      isValidating,
      errors,
    },
    watch,
    trigger: checkForm,
  } = formMethods;

  const crtName = watch('name');

  const hiddenFields = useMemo((): Partial<
    Record<keyof InformationsSchemaT, boolean>
  > => {
    return Object.entries(hidden).reduce(
      (prev, [key, hide]) => ({
        ...prev,
        [key]: hide && key in defaultValues,
      }),
      {} satisfies Partial<Record<keyof InformationsSchemaT, boolean>>
    );
  }, [hidden, defaultValues]);

  useEffect(() => {
    if (
      'name' in defaultValues &&
      (disabled?.withOrganizationNameVerification ?? true)
    ) {
      checkForm();
    }
  }, []);

  const onSubmit: SubmitHandler<InformationsSchemaT> = (data) => {
    return onNext(data);
  };

  return (
    <FormProvider {...formMethods}>
      <Stack
        spacing={4}
        component="form"
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h2">
            {t`Dîtes-nous en plus sur votre univers professionnel :`}
          </Typography>
        </Stack>

        {!hiddenFields?.name && (
          <TextFieldInput
            control={control}
            name="name"
            disabled={disabled?.name ?? false}
            required
            label={t`Nom de l'entreprise`}
            InputProps={{
              endAdornment: isValidating ? (
                <CircularProgress size="20px" />
              ) : (
                <InputAdornment position="end">
                  {'name' in errors ? (
                    <CloseIcon fontSize="small" color="error" />
                  ) : crtName && crtName.trim() !== '' ? (
                    <CheckIcon fontSize="small" color="primary" />
                  ) : null}
                </InputAdornment>
              ),
            }}
          />
        )}
        {!hiddenFields?.email && (
          <TextFieldInput
            control={control}
            name="email"
            disabled={disabled?.email ?? false}
            required
            autoComplete="email"
            label={t`Email de l'entreprise`}
            helperText={
              disabled?.withEmailVerification ?? true
                ? t`Un mail de confirmation avec un code à renseigner vous sera envoyé afin de valider la création de votre compte.`
                : null
            }
          />
        )}
        {!hiddenFields?.job && (
          <TextFieldInput
            control={control}
            name="job"
            required
            disabled={disabled?.job ?? false}
            label={t`Votre fonction`}
          />
        )}
        {!hiddenFields?.phone && (
          <PhoneNumberInput
            control={control}
            name="phone"
            required
            disabled={disabled?.phone ?? false}
            label={t`Numéro de téléphone`}
          />
        )}

        <Typography
          fontStyle="italic"
          fontWeight="bolder"
          fontSize={12}
          textAlign="center"
        >
          <Trans>
            En savoir plus sur votre univers professionnel et vos besoins nous
            permet de vous proposer les outils adaptés à vos besoins.
          </Trans>
        </Typography>

        {!!submitBtns ? (
          submitBtns
        ) : (
          <Stack alignItems="center">
            <Button
              variant="contained"
              type="submit"
              disabled={!isValid || isSubmitting || isSubmitSuccessful}
            >
              Continuer
            </Button>
          </Stack>
        )}
      </Stack>
    </FormProvider>
  );
};
