import {
  OrganizationActivity,
  type OrganizationActivityT,
  OrganizationVolume,
} from '@prismo-io/schemas';
import { z } from 'zod';

export const VolumeSchema = (
  useCases: (OrganizationActivityT | 'OTHERS')[]
) => {
  if (useCases.includes(OrganizationActivity.Enum.HR_DEVELOPMENT)) {
    return z.object({
      talentVolume: z.enum([
        OrganizationVolume.Enum.LESS_THAN_50,
        OrganizationVolume.Enum.BETWEEN_50_AND_250,
        OrganizationVolume.Enum.GREATER_THAN_250,
      ]),
    });
  }

  if (useCases.includes(OrganizationActivity.Enum.TRAINING)) {
    return z.object({
      talentVolume: z.enum([
        OrganizationVolume.Enum.LESS_THAN_50,
        OrganizationVolume.Enum.BETWEEN_50_AND_250,
        OrganizationVolume.Enum.GREATER_THAN_250,
      ]),
    });
  }

  if (useCases.includes(OrganizationActivity.Enum.RECRUITMENT)) {
    return z.object({
      talentVolume: z.enum([
        OrganizationVolume.Enum.LESS_THAN_10,
        OrganizationVolume.Enum.BETWEEN_10_AND_50,
        OrganizationVolume.Enum.BETWEEN_50_AND_250,
        OrganizationVolume.Enum.GREATER_THAN_250,
      ]),
    });
  }

  if (useCases.includes(OrganizationActivity.Enum.COACHING)) {
    return z.object({
      talentVolume: z.enum([
        OrganizationVolume.Enum.LESS_THAN_10,
        OrganizationVolume.Enum.BETWEEN_10_AND_50,
        OrganizationVolume.Enum.BETWEEN_50_AND_250,
        OrganizationVolume.Enum.GREATER_THAN_250,
      ]),
    });
  }

  if (useCases.includes('OTHERS')) {
    return z.object({
      talentVolume: z.enum([
        OrganizationVolume.Enum.LESS_THAN_10,
        OrganizationVolume.Enum.BETWEEN_10_AND_50,
        OrganizationVolume.Enum.BETWEEN_50_AND_250,
        OrganizationVolume.Enum.GREATER_THAN_250,
      ]),
    });
  }

  return z.object({
    talentVolume: OrganizationVolume,
  });
};

export type VolumeSchemaT = z.infer<ReturnType<typeof VolumeSchema>>;
