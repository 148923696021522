'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, t } from '@lingui/macro';
import { HelpOutline } from '@mui/icons-material';
import {
  IconButton,
  Stack,
  ToggleButton,
  Tooltip,
  Typography,
} from '@mui/material';
import type { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Button,
  TextFieldInput,
  ToggleButtonGroupInput,
} from '@prismo-io/design-system';
import { OrganizationActivity } from '@prismo-io/schemas';
import { useUsermaven } from '@prismo-io/tracking';
import { type FC, type ReactNode, useMemo } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { UseCaseSchemaRefined, type UseCaseSchemaT } from './schema';

export type UseCaseFormProps = {
  defaultValues?: Partial<UseCaseSchemaT>;
  onSubmit: SubmitHandler<UseCaseSchemaT>;
  submitBtns?: ReactNode;
  withFreeOption?: boolean;
};

export const UseCaseForm: FC<UseCaseFormProps> = (props) => {
  const {
    onSubmit: onNext,
    defaultValues = {},
    submitBtns = undefined,
    withFreeOption = false,
  } = props;

  const schema = useMemo(
    () => UseCaseSchemaRefined(withFreeOption),
    [withFreeOption]
  );

  const formMethods = useForm<UseCaseSchemaT>({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues: {
      useCases: [],
      customActivity: null,
      ...defaultValues,
    },
  });

  const {
    control,
    formState: { isValid, isSubmitting, isSubmitSuccessful },
    watch,
  } = formMethods;

  const usermaven = useUsermaven();

  const onSubmit: SubmitHandler<UseCaseSchemaT> = (data) => {
    usermaven.track('usage_chosen', { usages: data.useCases });

    return onNext(data);
  };

  const isUpMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const useCases = watch('useCases');

  const withCustomActivity = useMemo(
    () => withFreeOption && useCases.includes('OTHERS'),
    [useCases, withFreeOption]
  );

  return (
    <FormProvider {...formMethods}>
      <Stack
        spacing={4}
        component="form"
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        <Stack direction="row" alignItems="center">
          <Typography variant="h2">
            <Trans>Qu'est-ce qui décrit le mieux votre activité ?</Trans>
          </Typography>
          <Tooltip
            title={t`Connaître l'usage que vous allez faire de notre outil nous permet de vous proposer une solution plus adaptée.`}
          >
            <IconButton size="small">
              <HelpOutline />
            </IconButton>
          </Tooltip>
        </Stack>
        <ToggleButtonGroupInput
          control={control}
          name="useCases"
          orientation={isUpMd ? 'horizontal' : 'vertical'}
          disabled={isSubmitSuccessful}
          color="primary"
          fullWidth
        >
          <ToggleButton value={OrganizationActivity.Enum.HR_DEVELOPMENT}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Trans>Développement RH</Trans>
              <Tooltip
                title={t`Je gère le développement des collaborateurs et leur mobilité interne dans une entreprise.`}
              >
                <HelpOutline fontSize="small" />
              </Tooltip>
            </Stack>
          </ToggleButton>
          <ToggleButton value={OrganizationActivity.Enum.TRAINING}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Trans>Formation</Trans>
              <Tooltip
                title={t`Je forme des apprenants et des équipes pour les aider à mieux collaborer et communiquer.`}
              >
                <HelpOutline fontSize="small" />
              </Tooltip>
            </Stack>
          </ToggleButton>
          <ToggleButton value={OrganizationActivity.Enum.RECRUITMENT}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Trans>Recrutement</Trans>
              <Tooltip
                title={t`J'accompagne les candidats dans le processus de recrutement et d'intégration.`}
              >
                <HelpOutline fontSize="small" />
              </Tooltip>
            </Stack>
          </ToggleButton>
          <ToggleButton value={OrganizationActivity.Enum.COACHING}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Trans>Coaching</Trans>
              <Tooltip title={t`J'aide les talents à valoriser leur profil.`}>
                <HelpOutline fontSize="small" />
              </Tooltip>
            </Stack>
          </ToggleButton>
          {withFreeOption && (
            <ToggleButton value="OTHERS">
              <Stack direction="row" alignItems="center" gap={1}>
                <Trans>Autres</Trans>
                <Tooltip
                  title={t`Mon activité professionnelle est différente.`}
                >
                  <HelpOutline fontSize="small" />
                </Tooltip>
              </Stack>
            </ToggleButton>
          )}
        </ToggleButtonGroupInput>

        {withCustomActivity && (
          <TextFieldInput
            name="customActivity"
            disabled={isSubmitSuccessful}
            color="primary"
            required={withCustomActivity}
            fullWidth
            label={t`Quelle est votre activité ?`}
          />
        )}

        {!!submitBtns ? (
          submitBtns
        ) : (
          <Stack alignItems="center">
            <Button
              variant="contained"
              type="submit"
              disabled={!isValid || isSubmitting || isSubmitSuccessful}
            >
              Valider
            </Button>
          </Stack>
        )}
      </Stack>
    </FormProvider>
  );
};
