import type { ApiClient } from '@prismo-io/schemas';

export const checkOrganizationNameDisposable = async (
  client: ApiClient,
  name: string
): Promise<boolean> => {
  const result = await client
    .from('tools_organizations')
    .select('id', { count: 'exact', head: true })
    .ilike('name', name.toLowerCase());

  return result.count ? result.count === 0 : true;
};
