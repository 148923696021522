import { t } from '@lingui/macro';
import { OrganizationActivity } from '@prismo-io/schemas';
import { z } from 'zod';

export const UseCaseSchema = (withFreeOption = false) =>
  z.object({
    useCases: z
      .array(
        withFreeOption
          ? OrganizationActivity.or(z.literal('OTHERS'))
          : OrganizationActivity
      )
      .min(1, t`Vous devez sélectionner au moins une option.`),
    customActivity: z.string().trim().nullish(),
  });

export const UseCaseSchemaRefined = (withFreeOption = false) =>
  UseCaseSchema(withFreeOption).refine(
    ({ useCases, customActivity }) =>
      withFreeOption && useCases.includes('OTHERS')
        ? !!customActivity && customActivity.length > 2
        : true,
    {
      path: ['customActivity'],
      message: t`Veuillez définir votre activité professionelle.`,
    }
  );

export type UseCaseSchemaT = z.infer<ReturnType<typeof UseCaseSchema>>;
