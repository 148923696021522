import { t } from '@lingui/macro';
import { matchIsValidTel } from '@prismo-io/design-system';
import type { ApiClient } from '@prismo-io/schemas';
import { z } from 'zod';
import { checkOrganizationNameDisposable } from './check-organization-name-disposable';

export const debounce = <F extends (...args: any) => any>(
  func: F,
  waitFor: number
) => {
  let timeout: ReturnType<typeof setTimeout>;

  const debounced = (...args: any) => {
    clearTimeout(timeout);
    let result;
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

const debounced = <F extends (...args: any) => Promise<any>>(
  callback: F,
  delay = 300
) => {
  let timer: ReturnType<typeof setTimeout>;

  return (...args: any) => {
    return new Promise((resolve, reject) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        try {
          const output = callback(...args);
          resolve(output);
        } catch (err) {
          reject(err);
        }
      }, delay);
    });
  };
};

const fn = debounced(checkOrganizationNameDisposable, 300);

export const InformationsSchema = (
  client: ApiClient,
  withOrganizationNameVerification: boolean
) =>
  z.object({
    name: z
      .string({
        required_error: t`Le nom de l'entreprise est obligatoire.`,
        invalid_type_error: t`Le nom de l'entreprise n'est pas valide.`,
      })
      .trim()
      .min(1, t`Veuillez définir le nom de l'entreprise.`)
      .refine(
        async (name) => {
          if (!name || name.trim() === '') {
            return false;
          }

          if (!withOrganizationNameVerification) {
            return true;
          }

          return await fn(client, name);
        },
        {
          message: t`Un compte professionnel existe déjà au nom de cette entreprise.`,
        }
      ),
    email: z
      .string({
        required_error: t`L'adresse email est obligatoire.`,
        invalid_type_error: t`L'adresse email n'est pas valide.`,
      })
      .email(t`L'adresse email n'est pas valide.`)
      .trim()
      .min(1, t`Veuillez définir l'adresse email.`),
    job: z
      .string({
        required_error: t`La fonction est obligatoire.`,
        invalid_type_error: t`La fonction n'est pas valide.`,
      })
      .trim()
      .min(1, t`Veuillez définir votre fonction.`),
    phone: z
      .string({
        required_error: t`Le numéro de téléphone est obligatoire.`,
        invalid_type_error: t`Le numéro de téléphone n'est pas valide.`,
      })
      .trim()
      .min(1, t`Veuillez définir votre numéro de téléphone.`)
      .refine((i) => matchIsValidTel(i), {
        message: t`Le numéro de téléphone n'est pas valide.`,
      }),
  });

export type InformationsSchemaT = z.infer<
  ReturnType<typeof InformationsSchema>
>;

export const InformationsSchemaRefine = (
  client: ApiClient,
  withOrganizationNameVerification: boolean
) => InformationsSchema(client, withOrganizationNameVerification);

export type InformationsSchemaRefineT = z.infer<
  ReturnType<typeof InformationsSchemaRefine>
>;
