import {
  type ApiClient,
  SendEmailVerifyCodeOrganizationCreationArgs,
} from '@prismo-io/schemas';
import { z } from 'zod';

export const sendVerificationCode = async (
  client: ApiClient,
  params: string
): Promise<boolean> => {
  const email = z.string().email().trim().min(1).parse(params);

  const args = SendEmailVerifyCodeOrganizationCreationArgs.parse({
    _email: email,
  });

  const { data } = await client
    .rpc('send_email_verify_code_organization_creation', args)
    .throwOnError();

  return data || false;
};
