import { t } from '@lingui/macro';
import { z } from 'zod';

export const VerificationCodeSchema = () =>
  z.object({
    verificationCode: z
      .string()
      .trim()
      .length(6, t`Le code de vérification doit comporter 6 chiffres.`),
  });

export type VerificationCodeSchemaT = z.infer<
  ReturnType<typeof VerificationCodeSchema>
>;
