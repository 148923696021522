import { z } from 'zod';

export const SendEmailVerifyCodeOrganizationCreationArgs = z.object({
  _email: z.string().email(),
});

export type SendEmailVerifyCodeOrganizationCreationArgsT = z.infer<
  typeof SendEmailVerifyCodeOrganizationCreationArgs
>;

export const SendEmailVerifyCodeOrganizationCreationReturns = z.boolean();

export type SendEmailVerifyCodeOrganizationCreationReturnsT = z.infer<
  typeof SendEmailVerifyCodeOrganizationCreationReturns
>;
